<template>
  <g :fill="color" fill-rule="nonzero" transform="translate(-8, -8) scale(0.05, 0.05)">
    <path
      id="Path_29264"
      :fill="color"
      data-name="Path 29264"
      d="M553.63,462.98l-2.97-1.11c-17.98-7.32-36.64-12.86-55.7-16.54,8.07-52.44-27.89-101.49-80.33-109.56s-101.49,27.89-109.56,80.33c-1.49,9.69-1.49,19.55,0,29.23-19.84,3.8-39.25,9.62-57.9,17.38l-.88.33c-4.81,1.9-7.17,7.33-5.27,12.14,1.85,4.7,7.1,7.08,11.85,5.38l.88-.33c22.43-10.14,46.5-16.17,71.06-17.79,11.83.87,23.42,3.83,34.21,8.75,12.91,5.8,26.78,9.18,40.91,9.96,14.13-.78,28-4.16,40.91-9.96,10.8-4.92,22.38-7.89,34.21-8.75,23.8,1.63,47.13,7.39,68.95,17.03l2.97,1.11c4.85,1.8,10.23-.68,12.03-5.53,1.8-4.85-.68-10.23-5.53-12.03h0l.14-.04ZM434.29,453.65c-10.8,4.92-22.38,7.89-34.21,8.75-11.83-.86-23.42-3.83-34.21-8.75-12.91-5.8-26.78-9.18-40.91-9.96h-1.18v-.24c-7.11-42.13,21.28-82.05,63.41-89.16,42.13-7.11,82.05,21.28,89.16,63.41,1.45,8.6,1.44,17.39-.04,25.98h-1.07c-14.13.78-28,4.16-40.91,9.96h-.03Z"
    />
    <path
      id="Path_29265"
      :fill="color"
      data-name="Path 29265"
      d="M249.65,439.9h19.34c5.17.01,9.36-4.17,9.38-9.34.01-5.17-4.17-9.37-9.34-9.38h-19.38c-5.17-.01-9.36,4.17-9.38,9.34-.01,5.17,4.17,9.37,9.34,9.38h.04Z"
    />
    <path
      id="Path_29266"
      :fill="color"
      data-name="Path 29266"
      d="M265.13,363.47l16.75,9.67c4.48,2.58,10.2,1.05,12.78-3.43,2.58-4.48,1.05-10.2-3.43-12.78l-16.75-9.67c-4.48-2.58-10.2-1.05-12.78,3.43-2.58,4.48-1.05,10.2,3.43,12.78h0Z"
    />
    <path
      id="Path_29267"
      :fill="color"
      data-name="Path 29267"
      d="M326.4,321.77c2.58,4.48,8.31,6.01,12.78,3.43,4.48-2.58,6.01-8.31,3.43-12.78l-9.67-16.75c-2.58-4.48-8.31-6.01-12.78-3.43-4.48,2.58-6.01,8.31-3.43,12.78h0l9.67,16.75Z"
    />
    <path
      id="Path_29268"
      :fill="color"
      data-name="Path 29268"
      d="M400.03,308.88c5.17,0,9.36-4.19,9.36-9.36v-19.34c-.01-5.17-4.21-9.35-9.38-9.34-5.15.01-9.33,4.18-9.34,9.34v19.34c0,5.17,4.19,9.36,9.36,9.36Z"
    />
    <path
      id="Path_29269"
      :fill="color"
      data-name="Path 29269"
      d="M460.86,325.19c4.47,2.58,10.19,1.05,12.78-3.42l9.67-16.75c2.58-4.48,1.05-10.2-3.43-12.78-4.48-2.58-10.2-1.05-12.78,3.43l-9.67,16.75c-2.58,4.48-1.04,10.2,3.43,12.78Z"
    />
    <path
      id="Path_29270"
      :fill="color"
      data-name="Path 29270"
      d="M513.5,374.4c1.65-.02,3.26-.47,4.67-1.32l16.75-9.67c4.48-2.58,6.01-8.31,3.43-12.78-2.58-4.48-8.31-6.01-12.78-3.43l-16.75,9.67c-4.48,2.58-6.01,8.3-3.43,12.78,1.67,2.9,4.77,4.69,8.12,4.68v.07Z"
    />
    <path
      id="Path_29271"
      :fill="color"
      data-name="Path 29271"
      d="M521.68,430.55c0,5.17,4.19,9.36,9.36,9.36h19.34c5.17-.01,9.35-4.21,9.34-9.38-.01-5.15-4.18-9.33-9.34-9.34h-19.34c-5.17,0-9.36,4.19-9.36,9.36Z"
    />
    <path
      id="Path_29272"
      :fill="color"
      data-name="Path 29272"
      d="M501.93,510.98l-.22-.08c-16.6-7.29-34.32-11.69-52.4-13-9.67.53-19.16,2.86-27.99,6.86-13.21,7.5-29.4,7.5-42.61,0-8.83-4-18.32-6.32-27.99-6.86-14.65.42-29.09,3.66-42.52,9.53-3,1.07-6.25,2.22-9.84,3.46-4.88,1.69-7.47,7.03-5.78,11.91,1.69,4.88,7.03,7.47,11.91,5.78h0c3.63-1.32,6.93-2.43,9.97-3.51,11.42-5.14,23.74-8,36.25-8.45,7.37.62,14.58,2.53,21.3,5.62,17.54,9.14,38.43,9.14,55.97,0,6.72-3.09,13.93-5,21.3-5.62,15.79,1.42,31.25,5.42,45.74,11.84l.22.08c4.83,1.84,10.23-.59,12.07-5.42,1.84-4.83-.59-10.23-5.42-12.07h0v-.07Z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
